<div class="vstack gap-5">
    <mat-select class="me-2 form-control" type="text" [(ngModel)]="currencyFormat.new" placeholder="$">
        <mat-option *ngFor="let format of dataSharedService.currencyFormatList" [value]="format">
            {{format}}</mat-option>
    </mat-select>
</div>
<div class="hstack gap-2 justify-content-end mt-5">
    <!-- <button class="btn btn-sm btn-primary" (click)="saveDateFormat()"
        [disabled]="dateFormat.new === dateFormat.old">Save</button> -->
    <button class="btn btn-sm btn-primary" (click)="saveCurrencyFormat()">Save</button>
</div>