<header>
    <div class="container-fluid">
        <div class="border-bottom">
            <div class="row align-items-center">
                <div class="col-12">
                    <h3 class="h3 ls-tight">Billing Reports</h3>
                </div>
            </div>
            <ul class="nav nav-tabs overflow-x border-0">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/reports/payroll">Payroll</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" routerLink="/reports/pay-bill" routerLinkActive="active">Billing</a>
                </li>
            </ul>
        </div>
    </div>
</header>

<div class="py-6 bg-surface-secondary">
    <div class="container-fluid vstack gap-6">
        <div class="card mb-0">
            <div class="card-body">
                <form [formGroup]="payBillSearchForm" class="row g-3" (ngSubmit)="onSubmit()">
                    <div class="row align-items-center mt-2">

                        <div class="col-md-4">
                            <label class="form-label">Select Type</label>
                            <div class="d-flex filter-select-type-section">
                                <mat-radio-group class="" aria-label="Select an option" formControlName="report_type">
                                    <mat-radio-button class="me-6" value='general_report'
                                        (change)="selectedReportType($event)">General
                                        Report</mat-radio-button>
                                    <mat-radio-button class="me-6" value='detailed_report'
                                        (change)="selectedReportType($event)">Detailed
                                        Report</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="form-label" for="selectTimeSheet">Start Date - End Date</label>
                            <div class="filter-daterangepicker">
                                <mat-form-field appearance="legacy" class="datePickerStyle">
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate formControlName="start_date">
                                        <input matEndDate formControlName="end_date"
                                            (dateChange)="DatepcikerEndDateChanged($event)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker touchUi="true" #picker></mat-date-range-picker>
                                </mat-form-field>

                                <mat-select panelClass="matRole timesheetDateFilterPanel" class="form-control"
                                    role="button" (selectionChange)="onFilterChange(picker)"
                                    [(ngModel)]="selectedFilterOption" [ngModelOptions]="{standalone: true}">
                                    <mat-option>Recently Submitted</mat-option>
                                    <mat-option [value]="option.label"
                                        *ngFor="let option of filterOptions">{{option.label}}</mat-option>
                                    <mat-option value="datePicker" (click)="onFilterChange(picker)">Date
                                        picker</mat-option>
                                    <mat-option value="{{custom_datepicker_text}}"
                                        class="d-none">{{custom_datepicker_text}}</mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="col-md-4 d-flex justify-content-sm-end filter-generate-section">
                            <button type="submit" [disabled]="!payBillSearchForm.valid || loading"
                                class="btn btn-sm btn-primary">
                                Generate
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div class="card">
            <div class="card-header border-bottom d-flex align-items-center">
                <h5 class="me-auto">Pay Bill Report Data</h5>
                <div class="d-flex hstack gap-6">
                    <div class="" *ngIf="payBillReportData && payBillReportData.length">
                        <input formControlName="candidate_uuid" style="display: none;" />
                        <app-select-search (onChange)="onSearchChange($event)" [endpoint]="API_CONSTANT.USERS"
                            [control]="payBillSearchForm.controls.candidate_uuid" controlName="candidate_uuid"
                            titleKey="name">
                        </app-select-search>
                    </div>
                    <div class="" *ngIf="payBillReportData && payBillReportData.length">
                        <div class="dropdown">
                            <button class="dropdown-button btn btn-sm btn-primary" type="button"
                                id="pay_bill_report_export" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-download"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="pay_bill_report_export">
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('excel')">
                                    <i class="bi bi-cloud-download"></i>
                                    Excel file
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('sage')">
                                    <i class="bi bi-cloud-download"></i>
                                    Sage Report
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('quick_book')">
                                    <i class="bi bi-cloud-download"></i>
                                    Quick Books Report
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('xero')">
                                    <i class="bi bi-cloud-download"></i>
                                    Xero Report
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('gusto')">
                                    <i class="bi bi-cloud-download"></i>
                                    Gusto Report
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item" (click)="exportIn('pdf')">
                                    <i class="bi bi-cloud-download"></i>
                                    PDF Report
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" *ngIf="payBillReportData && payBillReportData.length">
                <table class="table table-hover table-nowrap" *ngIf="reportType == 'general_report'">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="ps-4">Contractor ID</th>
                            <th scope="col">Contractor Name</th>
                            <th scope="col">Company</th>							
                            <th scope="col">Job</th>							
							<th scope="col" *ngIf="trackHours != 2">Total Hours</th>
                            <th scope="col" *ngIf="trackHours != 2">Regular Hours</th>
                            <th scope="col" *ngIf="trackHours != 2">OT Hours</th>
                            <th scope="col" *ngIf="trackHours != 2">Double OT Hours</th> 
							<th scope="col" *ngIf="trackHours != 2">Bill Rate (Hourly)</th>
                            <th scope="col" *ngIf="trackHours == 2">Total Days</th>
							<th scope="col" *ngIf="trackHours == 2">Bill Rate (Daily)</th>
                            <th scope="col">Bill</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payBill of payBillReportData">
                            <td>{{payBill.contractor_id}}</td>
                            <td>{{payBill.candidate}}</td>							
                            <td>{{payBill.client}}</td>
							<td>{{payBill.job}}</td>
							<td *ngIf="trackHours != 2">{{payBill.total_hours}}</td>
							<td *ngIf="trackHours != 2">{{payBill.regular_hours}}</td>
							<td *ngIf="trackHours != 2">{{payBill.ot_hours}}</td>
							<td *ngIf="trackHours != 2">{{payBill.double_ot_hours}}</td>							
							<td *ngIf="trackHours != 2">{{payBill.bill_rate_hourly}}</td>
                            <td *ngIf="trackHours == 2">{{payBill.total_days}}</td>
							<td *ngIf="trackHours == 2">{{currencyFormat}} {{payBill.bill_rate_hourly}}</td>
                            <td>{{currencyFormat}} {{payBill.total_bill}}</td>
                        </tr>
                    </tbody>
                </table>
				
                <table class="table table-hover table-nowrap" *ngIf="reportType == 'detailed_report'">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="ps-4">Contractor ID</th>
                            <th scope="col">Contractor Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Job</th>
                            <th scope="col">Status</th>
                            <th *ngFor="let reportDate of payBillReportDates">
                                {{reportDate}}
                            </th>
                            <th *ngIf="trackHours != 2">Total Hours</th>
                            <th *ngIf="trackHours == 2">Total Days</th>
                            <th scope="col">Bill</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payBill of payBillReportData">
                            <td>{{payBill.contractor_id}}</td>
                            <td>{{payBill.candidate}}</td>
                            <td>{{payBill.client}}</td>
                            <td>{{payBill.job}}</td>
                            <td>{{payBill.timesheet_status}}</td>
                            <td align="center" *ngFor="let reportInfo of payBill.report_info">
                                <b *ngIf="trackHours != 2">{{reportInfo.hours}}</b>
                                <b *ngIf="trackHours == 2">{{reportInfo.total_days}}</b>
                            </td>
                            <td *ngIf="trackHours != 2">{{payBill.total_hours}}</td>
                            <td *ngIf="trackHours == 2">{{payBill.total_days}}</td>
                            <td>{{currencyFormat}} {{payBill.total_bill}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="payBillReportData && payBillReportData.length == 0">
                <table class="table table-hover table-nowrap">
                    <tbody>
                        <tr>
                            <td colspan="9">Please select report type and dates to generate the report</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>