import { Component, OnInit } from '@angular/core';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-currency-format',
  templateUrl: './currency-format.component.html',
  styleUrls: ['./currency-format.component.css']
})
export class CurrencyFormatComponent implements OnInit {
  currencyFormat: any = {
    old: localStorage.getItem('currencyFormat') ? localStorage.getItem('currencyFormat') : '',
    new: localStorage.getItem('currencyFormat') ? localStorage.getItem('currencyFormat') : ''
  }

  constructor(public dataSharedService: DataSharedService) { 
  }

  ngOnInit(): void {
  }

  async saveCurrencyFormat() {
    localStorage.setItem('currencyFormat', this.currencyFormat.new);

    await this.dataSharedService.setCurrencyFormat(this.currencyFormat.new).subscribe(res => {
      console.log('currency format res', res);
    });
    this.currencyFormat.old = this.currencyFormat.new;
  }
}
